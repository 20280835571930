<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateAttendanceAction)"
        @reset.prevent="resetForm"
        v-if="projectinterferingData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="6">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Assertion
            </h6>
          </b-col>
          <b-col cols="6">
            <b-button
              v-if="$router.name == 'projectinterfering-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'projectinterfering-view',
                  params: { id: projectinterferingData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'projectinterfering-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'projectinterfering-view',
                  params: { id: projectinterferingData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'projectinterfering-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste d'Assertions</span>
            </b-button>
          </b-col>
        </b-row>

        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="InfoIcon" size="18" />
              <span class="align-middle ml-50">Références</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-col cols="12" md="12">
              <b-row>
                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom / Designation"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="projectinterferingData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Etat de la victime"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Nom de l'assertion
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                      label="Categorie"
                      label-for="category"
                    >
                      <b-form-input
                        id="category"
                        v-model="projectinterferingData.category"
                        trim
                        placeholder="Ex: Indicateur de Suivi Dossier"
                        class=""
                      />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="projectinterferingData.description"
                        trim
                        placeholder="Ex: Comment exploiter l'assertion"
                        class=""
                      />
                    </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-card-body>
        </b-card>

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="projectinterferingData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";

import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormDatepicker,
  BFormTextarea,
  BCard,
  BCardBody,
  BCardText,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import storeModule from "./storeModule";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const moduleName = "projectinterfering";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BCardTitle,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ToastificationContent,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      errors: [],
      jcJuristOptions: [],
      interval: null,
      time: null,
      axiosMethod: null,
    };
  },
  props: {
    projectinterferingData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    if (router.currentRoute.name == moduleName + "-add-new") {
      this.operationTitle = "Nouvelle ";
      this.operationIcon = "AddIcon";
      this.editableRoute = true;

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.operationTitle = "Détails de l'";
      this.operationIcon = "EyeIcon";
      this.axiosMethod = "get";

      this.actionName = "Visionner ";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.operationTitle = "Modification de l' ";
      this.operationIcon = "EditIcon";
      this.axiosMethod = "put";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }

    // console.log(this.$refs);
    this.initTrHeight();
    
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      var now = new Date();
      //
      this.$props.projectinterferingData.sku_projectinterfering = "projectinterfering-" + now.getTime();
      this.$props.projectinterferingData.sku_projectinterfering = "";
      this.$props.projectinterferingData.type = "";
      this.$props.projectinterferingData.method = "";
      this.$props.projectinterferingData.direction = "";
      // update row heigth
      this.initTrHeight();
    },
    async addUpdateAttendanceAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "projectinterfering-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "projectinterfering-view") {
        method = "get";
      }
      if (router.currentRoute.name == "projectinterfering-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.projectinterferingData.id;
      }

      // finalise form data
      // delete this.$props.moduleData.image_url;
      formData = this.$props.projectinterferingData;

      myApi({
        url: "projectinterfering" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          console.log(response);

          if (response.status == 200 || response.status == 201) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "projectinterfering-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message); 
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.actionName = defaultActionText;
          this.errors.push(error.response.data.message);
          this.showToast(
            "Erreur Exceptionnelle Survenu",
             error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    checkFormInputs() {
      var errors = 0;
      if (errors > 0) {
        return false;
      } else {
        return true;
      }
    },
    goToPrecAttendance() {
      router.push({ name: "projectinterfering-view" });
    },
    goToNextAttendance() {
      router.push({ name: "projectinterfering-list" });
    },
    goToList() {
      router.push({ name: "projectinterfering-list" });
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-projectinterfering";
    var time = ref();
    setInterval(() => {
      time.value = new Date().toString("hh:mm:ss tt");
    }, 1000);

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.projectinterferingData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);
    //
    // let itemsList = ref([]);
    // store.dispatch("app-projectinterfering/fetchAttendances").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // // fill items
    // let itemsOptions = itemsList;

    let directionOptions = [
      { label: "Entrée", value: "in" },
      { label: "Sortie", value: "out" },
      { label: "Non-précisé", value: "-" },
    ];

    let typeOptions = [
      { label: "Matière civile", value: "Juridique" },
      { label: "Matière pénale", value: "Judiciaire" },
      { label: "Matière administrative", value: "Administrative" },
      { label: "Autres", value: "-" },
    ];

    const maritalOptions = [
      { label: "Marié(e)", value: "Marié" },
      { label: "Célibaire", value: "Célibaire" },
      { label: "Séparé(e)", value: "Séparé(e)" },
      { label: "Divorcé(e)", value: "Divorcé(e)" },
      { label: "Veuf(ve)", value: "Veuf(ve)" },
      { label: "Autre", value: "-" },
    ];

    const genderOptions = [
      { label: "Homme", value: "M" },
      { label: "Femme", value: "F" },
      { label: "Autre", value: "-" },
    ];

    const jcViolenceTypes = [
      { label: "Mariage forcé", value: "Mariage forcé" },
      { label: "Violence physique", value: "Violence physique" },
      { label: "Viol", value: "Viol" },
      { label: "Agression sexuelle", value: "Agression sexuelle" },
      { label: "Violence sexuelle", value: "Violence sexuelle" },
      { label: "Violence psychologique/affective/harcelement", value: "Violence psychologique/affective/harcelement" },
      { label: "Coups et blessures volontaires", value: "Coups et blessures volontaires" },
      { label: "Deni des ressources, d'opportunités et de services", value: "Deni des ressources, d'opportunités et de services" },
      { label: "Population hôte/résidents-es", value: "Population hôte/résidents-es" },
      { label: "Souteneur et proxénétisme (exploitation et abus sexuels)", value: "Souteneur et proxénétisme (exploitation et abus sexuels)" },
      { label: "Autres violations des droits", value: "Autres violations des droits" },
    ]; 

    return {
      time,
      refFormObserver,
      getValidationState,
      resetForm,
      directionOptions,
      typeOptions,
      genderOptions,
      jcViolenceTypes,
      maritalOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
